.App {
    text-align: center;
    position: relative;
    max-width: 1070px;
    min-width: 650px;
    margin: auto;
}

*{
    font-family: "Open Sans", sans-serif;
}
html{
    height: 100vh;
    width: 100vw;
    background-image: url("./background.jpg");
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.slide:hover {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
}

.slide-img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}

.slide-p {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    max-height: 120px;
    overflow: clip;
    text-align: left;
    word-break: break-word;
    white-space: normal;
    font-weight: 300;
    font-size: 20px;
}

.right-arrow {
    top: calc(50% - 22px);
    position: absolute;
    right: calc(50% - 16px);
    user-select: none;
}

.left-arrow {
    top: calc(50% - 22px);
    position: absolute;
    left: calc(50% - 16px);
    user-select: none;
}

.right-arrow-hover {
    top: calc(50% - 22px);
    position: absolute;
    right: calc(50% - 16px);
    filter: invert(14%) sepia(86%) saturate(5128%) hue-rotate(341deg) brightness(74%) contrast(98%);
    user-select: none;
}

.left-arrow-hover {
    top: calc(50% - 22px);
    position: absolute;
    left: calc(50% - 16px);
    filter: invert(14%) sepia(86%) saturate(5128%) hue-rotate(341deg) brightness(74%) contrast(98%);
    user-select: none;
}

.right-arrow-box {
    position: absolute;
    left: 91%;
    top: 0;
    height: 100%;
    width: 7%;
    background: #F5F5F5;
    cursor: pointer;
}

.left-arrow-box {
    position: absolute;
    left: 1%;
    height: 100%;
    width: 7%;
    background: #F5F5F5;
    cursor: pointer;
}

.year {
    font-weight: 700;
    text-align: left;
}

.popout {
    animation: fadeIn .5s;
    z-index: 2;
    position: absolute;
    display: grid;
    grid-template-columns: [start] 35% [img_end] 10% [caption_end] 45% [paragraph_end] 10% [end];
    grid-template-rows: [start] 20% [year_end] 60% [close_start] 5% [img_end] 15% [end];
    top: 0;
    background: white;
    height: 100%;
    width: 81%;
    left: 9%;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.hidden-popout {
    animation: fadeOut .5s;
    pointer-events: none;
    visibility: hidden;
    position: absolute;
    display: grid;
    grid-template-columns: [start] 35% [img_end] 55% [paragraph_end] 10% [end];
    grid-template-rows: [start] 20% [year_end] 60% [close_start] 5% [img_end] 15% [end];
    top: 0;
    background: white;
    height: 100%;
    width: 81%;
    left: 9%;
}

@keyframes fadeOut {
    0% { opacity: 1; visibility: visible}
    100% { opacity: 0; visibility: hidden }
}

.popout-img {
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    grid-column-start: start;
    grid-column-end: img_end;
    grid-row-start: year_end;
    grid-row-end: img_end;
}

.popout-year {
    font-size: 2em;
    font-weight: 700;
    text-align: left;
    grid-column-start: start;
    grid-column-end: img_end;
    grid-row-start: start;
    grid-row-end: year_end;
}

.popout-p {
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    grid-column-start: img_end;
    grid-column-end: paragraph_end;
    grid-row-start: year_end;
    grid-row-end: img_end;
}

.popout-quit {
    cursor: pointer;
    text-decoration: underline #AB2734;
    color: #AB2734;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-style: italic;
    grid-column-start: img_end;
    grid-column-end: paragraph_end;
    grid-row-start: close_start;
    grid-row-end: img_end;
    margin: 0;
    text-align: left;
}

.popout-caption-box{
    position: relative;
    grid-column-start: start;
    grid-row-start: img_end;
    grid-row-end: end;
    text-align: left;
}

.popout-caption{
    position: absolute;
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 10pt;
}

.exit-button{
    justify-self: end;
    grid-column-start: paragraph_end;
    grid-column-end: end;
    grid-row-start: start;
    grid-row-end: year_end;
    padding-right: 10%;
    padding-top: 10%;
    cursor: pointer;
}

.inner {
    width: 100%;
}

.read-more-showing {
    color: #AB2734;
    text-decoration: underline #AB2734;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-style: italic;
    visibility: visible;
    cursor: pointer;
    text-align: left;
}

.read-more-hidden {
    visibility: hidden;
    color: #AB2734;
    text-decoration: underline #AB2734;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-style: italic;
    cursor: pointer;
    text-align: left;
}

.dates-tracker{
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
    font-style: italic;
    /* float: right; */
    text-align: right;
    width: 88%;
}

.carousel-holder{
    position: relative;
}

.carousel-outer-box{
    display: flex;
    width: 65%;
    margin: 4% auto 50px;
    background: white;
    flex-direction: column;
    min-width: 650px;
}

.carousel-outer-box h1{
    font-weight: 400;
    color: #ba0c2f;
    padding-left: 12%;
}

.carousel-outer-box-p{
    font-weight: 300;
    padding-left: 12%;
    color: #000000;
    width: 50%;
}

.carousel-holder-holder{
    display: table-cell;
    vertical-align: middle;
}

.return-button{
    padding: 7px;
    width: fit-content;
    color: white;
    margin: auto auto 20px;
    background: #ba0c2f;
    font-weight: 700;
    font-size: 1em;
    border: none;
    text-decoration: none;
}

.site-header{
    width: 100%;
    background: #ba0c2f;
    height: fit-content;
    display: flex;
    justify-content: space-between;
}

.site-header img{
    margin-left: 75px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 50px;
}

.site-header input{
    background: #ba0c2f;
    border: none;
    padding: 10px;
    color: white;
    margin-top: 12px;
    margin-bottom: 12px;
}

::placeholder{
    color: white;
}

.input-box{
    margin-right: 5%;
    display: flex;
}

.input-box button{
    margin-bottom: 12px;
    margin-top: 12px;
    border: none;
    background: #ba0c2f;
}

@media only screen and (max-width: 650px) {

    .App{
        min-width: 300px;
    }

    .left-arrow-box{
        max-height: 500px;
        min-height: 500px;
    }

    .right-arrow-box{
        max-height: 500px;
        min-height: 500px;
    }

    .slide:hover {
        box-shadow: none;
    }

    .carousel-item{
        width: 98% !important;
    }

    .mobile-popout {
        position: absolute;
        width: 89%;
        background: #ffffff;
        z-index: 2;
        top: 67%;
        text-align: left;
        word-break: break-word;
        white-space: normal;
    }

    .mobile-popout-collapse {
        color: #AB2734;
        text-decoration: underline #AB2734;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        font-style: italic;
        cursor: pointer;
    }

    .mobile-expanded {
        display: -webkit-box;
        -webkit-line-clamp: 100;
        -webkit-box-orient: vertical;
        max-height: none;
        overflow: clip;
        text-align: left;
        word-break: break-word;
        white-space: normal;
        font-weight: 300;
        font-size: 20px;
    }

    .dates-tracker{
        width: 27%;
        margin: auto auto 10px;
        text-align: center;
    }

    .carousel-outer-box{
        min-width: 0;
        margin: 0;
        width: 100%;
    }

    .carousel-outer-box-p{
        padding-right: 12%;
        width: auto;
    }

    html{
        background-image: none;
    }

    .carousel-outer-box h1 {
        font-weight: 400;
        color: #ba0c2f;
        margin: auto;
        padding: 0;
    }
}

@media only screen and (max-width: 800px) {
    .popout-p{
        font-size: 16px;
        font-weight: 300;
        font-family: "Open Sans", sans-serif;
        text-align: left;
        grid-column-start: img_end;
        grid-column-end: end;
        grid-row-start: year_end;
        grid-row-end: img_end;
        padding-left: 10%;
    }

    .popout-caption {
        position: absolute;
        bottom: 0;
        margin: 0 0 5px;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        font-size: 8pt;
    }
}

@media only screen and (max-width: 1150px){
    .popout-img{
        height: 75%;
        padding-top: 20px;
    }

    .popout-p{
        padding-left: 10px;
    }
}

@media only screen and (max-width: 1400px){
    .popout-p{
        grid-column-end: end;
        padding-left: 30px;
        overflow: scroll;
    }
}
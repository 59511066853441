.carousel {
    display: flex;
    overflow: hidden;
    width: 80%;
    height: auto;
    margin: auto;
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 95%;
    color: black;
    padding: 1% 1% 0;
    width: 23%;
}

.carousel-item > * {
    padding-left: 5%;
    padding-right: 5%;
}

@media only screen and (max-width:650px){
    .carousel {
        display: block;
        overflow: hidden;
        width: 80%;
        height: auto;
        margin: auto;
    }
    img.right-arrow{
        width: 95%;
        right: 0;
    }
    img.right-arrow-hover{
        width:95%;
        right: 0;
    }
    img.left-arrow{
        width: 95%;
        left: 0;
    }
    img.left-arrow-hover{
        width: 95%;
        left: 0;
    }

}
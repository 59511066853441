.bsu-header {

  //---------------------------------------
  //---------------------------------------
  //OVERRIDES
  //---------------------------------------
  //---------------------------------------

  font-family: 'Open Sans', sans-serif;

  button:hover, button:focus {
    .bsu-search-icon {
      path {
        fill: #ba0c2f;
      }
    }
  }

  .bsu-search-icon {
    height:20px;
    width:20px;
    margin-top: 8px;
    margin-left: 2px;
    path {
      fill:white;
    }
  }

  #navUtility.navbar-inverse .navbar-form {
    margin-right: 0 !important;
  }


  //---------------------------------------
  //---------------------------------------
  //COPIED FROM BSU.EDU as of November 2022
  //---------------------------------------
  //---------------------------------------

  /*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-637973620180000000/bsu-bootstrap.css */
  *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
  *:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
  #offcanvasOverlay{position:fixed;z-index:10000;top:0;left:0;right:0;bottom:0;background:rgba(255,255,255,.5);display:none;}
  /*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-638016029940000000/bsu-bootstrap-templates.css */
  @media print{
    *,*:before,*:after{color:#000!important;box-shadow:none!important;text-shadow:none!important;}
  }

  /*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-637973620180000000/bsu-bootstrap.css */
  a{background-color:transparent;}
  a:active,a:hover{outline:0;}
  *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
  *:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
  a{color:#ba0c2f;text-decoration:none;font-weight:600;}
  a:hover,a:focus{color:#ba0c2f;text-decoration:underline;-webkit-transition:color .2s ease-in-out;-o-transition:color .2s ease-in-out;transition:color .2s ease-in-out;-webkit-transition:background-color .2s ease-in-out;-o-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out;}
  a:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
  ul{margin-top:0;margin-bottom:11px;}
  .container{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px;}
  .container:before,.container:after{content:" ";display:table;}
  .container:after{clear:both;}
  @media (min-width:768px){
    .container{width:750px;}
  }
  @media (min-width:992px){
    .container{width:970px;}
  }
  @media (min-width:1200px){
    .container{width:1170px;}
  }
  .container{min-width:320px;}
  /*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-638016029940000000/bsu-bootstrap-templates.css */
  @media print{
    *,*:before,*:after{color:#000!important;box-shadow:none!important;text-shadow:none!important;}
    a,a:visited{text-decoration:none;border-bottom:1px solid #000;}
    a[href]:after{content:" (" attr(href) ")";font-size:6pt;text-transform:lowercase!important;border-bottom:5px solid #fff!important;}
    a[href^="/"]:after{content:" (https://www.bsu.edu" attr(href) ")"!important;font-size:6pt!important;text-transform:lowercase!important;border-bottom:5px solid #fff!important;}
    #topmenu{display:none!important;}
  }
  #topmenu{color:#000;background:#fff;font-family:"Open Sans",Arial,sans-serif;font-style:normal;font-weight:bold;text-align:center;margin-bottom:0;font-size:14px;display:block;}
  #topmenu:before{content:"";width:100%;min-height:10px;background:#ba0c2f;display:block;}
  #topmenu ul{list-style-type:none;margin:0;padding:0;overflow:hidden;}
  #topmenu ul.topnavleft{float:left;}
  #topmenu ul.topnavright{float:right;}
  #topmenu ul li{float:left;margin-right:17px;padding:0;}
  #topmenu ul li a{display:block;color:#000;text-decoration:none;padding:9px 3px;}
  #topmenu ul li a.tacticalbtn{color:#ba0c2f;padding:7px 16px;background:#f2f2f2;}
  #topmenu ul li a:hover{text-decoration:underline;}
  #topmenu ul li a:focus{text-decoration:underline;outline:0;}
  #topmenu ul li a.tacticalbtn:hover,#topmenu ul li a.tacticalbtn:focus{color:#fff;background:#54585a;text-decoration:none;}
  #topmenu ul li:last-child{margin-right:0;}
  @media screen and (max-width:992px){
    #topmenu{display:none;}
  }

  /*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-637973620180000000/bsu-bootstrap.css */
  a{background-color:transparent;}
  a:active,a:hover{outline:0;}
  img{border:0;max-width:100%;height:auto!important;}
  button,input{color:inherit;font:inherit;margin:0;}
  button{overflow:visible;}
  button{text-transform:none;}
  button{-webkit-appearance:button;cursor:pointer;}
  button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0;}
  input{line-height:normal;}
  *{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
  *:before,*:after{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
  input,button{font-family:inherit;font-size:inherit;line-height:inherit;}
  a{color:#ba0c2f;text-decoration:none;font-weight:600;}
  a:hover,a:focus{color:#ba0c2f;text-decoration:underline;-webkit-transition:color .2s ease-in-out;-o-transition:color .2s ease-in-out;transition:color .2s ease-in-out;-webkit-transition:background-color .2s ease-in-out;-o-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out;}
  a:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
  img{vertical-align:middle;border-radius:3px;}
  .sr-only{position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);border:0;background:#000;}
  h5{font-family:"Open Sans",Arial,sans-serif;font-weight:600;line-height:1.1;letter-spacing:1px;}
  h5{color:#ba0c2f;}
  h5{margin-top:11px;margin-bottom:11px;}
  h5{font-size:16px;}
  .small{font-size:75%;}
  ul{margin-top:0;margin-bottom:11px;}
  .container{margin-right:auto;margin-left:auto;padding-left:15px;padding-right:15px;}
  .container:before,.container:after{content:" ";display:table;}
  .container:after{clear:both;}
  @media (min-width:768px){
    .container{width:750px;}
  }
  @media (min-width:992px){
    .container{width:970px;}
  }
  @media (min-width:1200px){
    .container{width:1170px;}
  }
  .row{margin-left:-15px;margin-right:-15px;}
  .row:before,.row:after{content:" ";display:table;}
  .row:after{clear:both;}
  @media screen and (max-width:480px){
    .row{margin-left:0;margin-right:0;}
  }
  .col-xs-12{position:relative;min-height:1px;padding-left:15px;padding-right:15px;}
  .col-xs-12{float:left;}
  .col-xs-12{width:100%;}
  .form-control{display:block;width:100%;height:36px;padding:6px 12px;font-size:16px;line-height:1.42858;color:#666;background-color:#fff;background-image:none;border:1px solid #ccc;border-radius:0;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075);box-shadow:inset 0 1px 1px rgba(0,0,0,.075);-webkit-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;-o-transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;transition:border-color ease-in-out .15s,box-shadow ease-in-out .15s;}
  .form-control:focus{border-color:#66afe9;outline:0;-webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);}
  .form-control::-moz-placeholder{color:#999;opacity:1;}
  .form-control:-ms-input-placeholder{color:#999;}
  .form-control::-webkit-input-placeholder{color:#999;}
  .input-group-sm>.form-control,.input-group-sm>.input-group-btn>.btn{height:30px;padding:5px 10px;font-size:12px;line-height:1.5;border-radius:0;}
  .btn{display:inline-block;margin-bottom:0;font-weight:700;text-align:center;text-transform:uppercase;vertical-align:middle;touch-action:manipulation;cursor:pointer;background-image:none;border:none;font-family:"Open Sans",Arial,sans-serif;white-space:nowrap;padding:6px 12px;font-size:16px;line-height:1.42858;border-radius:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-transition:background-color .2s ease-in-out;-o-transition:background-color .2s ease-in-out;transition:background-color .2s ease-in-out;}
  .btn:focus,.btn:active:focus{outline:thin dotted;outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
  .btn:hover,.btn:focus{color:#fff;text-decoration:none;}
  .btn:active{outline:0;background-image:none;-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);box-shadow:inset 0 3px 5px rgba(0,0,0,.125);}
  .btn-default{color:#fff;background-color:#54585a;border-color:#fff;}
  .btn-default:hover,.btn-default:focus,.btn-default:active{color:#fff;background-color:#3b3e40;border-color:#e0e0e0;}
  .btn-default:active{background-image:none;}
  .container{min-width:320px;}
  .input-group{position:relative;display:table;border-collapse:separate;}
  .input-group .form-control{position:relative;z-index:2;float:left;width:100%;margin-bottom:0;}
  .input-group-btn,.input-group .form-control{display:table-cell;}
  .input-group-btn{width:1%;white-space:nowrap;vertical-align:middle;}
  .input-group .form-control:first-child,.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle){border-bottom-right-radius:0;border-top-right-radius:0;}
  .input-group-btn:last-child>.btn{border-bottom-left-radius:0;border-top-left-radius:0;border-bottom-right-radius:0;border-top-right-radius:0;}
  .input-group-btn{position:relative;font-size:0;white-space:nowrap;}
  .input-group-btn>.btn{position:relative;}
  .input-group-btn>.btn:hover,.input-group-btn>.btn:focus,.input-group-btn>.btn:active{z-index:2;}
  .input-group-btn:last-child>.btn{margin-left:-1px;}
  .nav{margin-bottom:0;padding-left:0;list-style:none;}
  .nav:before,.nav:after{content:" ";display:table;}
  .nav:after{clear:both;}
  .nav>li{position:relative;display:block;}
  .nav>li>a{position:relative;display:block;padding:10px 15px;}
  .nav>li>a:hover,.nav>li>a:focus{text-decoration:none;background-color:#eee;}
  .navbar{position:relative;min-height:48px;margin-bottom:22px;border:1px solid transparent;}
  .navbar:before,.navbar:after{content:" ";display:table;}
  .navbar:after{clear:both;}
  @media (min-width:768px){
    .navbar{border-radius:0;}
  }
  .navbar-header:before,.navbar-header:after{content:" ";display:table;}
  .navbar-header:after{clear:both;}
  .container>.navbar-header{margin-right:-15px;margin-left:-15px;}
  @media (min-width:768px){
    .container>.navbar-header{margin-right:0;margin-left:0;}
  }
  .navbar-fixed-top{position:fixed;right:0;left:0;z-index:1030;}
  @media (min-width:768px){
    .navbar-fixed-top{border-radius:0;}
  }
  .navbar-fixed-top{top:0;border-width:0 0 1px;}
  .navbar-toggle{position:relative;float:right;margin-right:15px;padding:10px;margin-top:7px;margin-bottom:7px;background-color:transparent;background-image:none;border:1px solid transparent;border-radius:0;}
  .navbar-toggle:focus{outline:0;}
  @media (min-width:768px){
    .navbar-toggle{display:none;}
  }
  .navbar-nav{margin:6.5px -15px;}
  .navbar-nav>li>a{padding-top:10px;padding-bottom:10px;line-height:1;}
  @media (min-width:768px){
    .navbar-nav{float:left;margin:0;}
    .navbar-nav>li{float:left;}
  }
  .navbar-form{margin-left:-15px;margin-right:-15px;padding:10px 15px;border-top:1px solid transparent;border-bottom:1px solid transparent;-webkit-box-shadow:inset 0 1px 0 rgba(255,255,255,.1),0 1px 0 rgba(255,255,255,.1);box-shadow:inset 0 1px 0 rgba(255,255,255,.1),0 1px 0 rgba(255,255,255,.1);margin-top:6px;margin-bottom:6px;}
  @media (min-width:768px){
    .navbar-form .form-control{display:inline-block;width:auto;vertical-align:middle;}
    .navbar-form .input-group{display:inline-table;vertical-align:middle;}
    .navbar-form .input-group .input-group-btn,.navbar-form .input-group .form-control{width:auto;}
    .navbar-form .input-group>.form-control{width:100%;}
  }
  @media (min-width:768px){
    .navbar-form{width:auto;border:0;margin-left:0;margin-right:0;padding-top:0;padding-bottom:0;-webkit-box-shadow:none;box-shadow:none;}
  }
  .navbar-form input[type=text]::-webkit-input-placeholder{color:#fff;}
  .navbar-form input[type=text]:-ms-input-placeholder{color:#fff;}
  .navbar-form input[type=text]::-moz-placeholder{color:#fff;}
  .navbar-form input[type=text]:-moz-placeholder{color:#fff;}
  .icon-bar{display:block;width:30px;height:4px;border-radius:2px;}
  .icon-bar+.icon-bar{margin-top:4px;}
  .icon-bar{background-color:#fff;-webkit-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;transition:all .2s ease-in-out;}
  .middle-bar{opacity:0;}
  .top-bar{-webkit-transform:rotate(45deg) translate(4px,5px);-ms-transform:rotate(45deg) translate(4px,5px);-o-transform:rotate(45deg) translate(4px,5px);transform:rotate(45deg) translate(4px,5px);}
  .bottom-bar{-webkit-transform:rotate(-45deg) translate(6px,-7px);-ms-transform:rotate(-45deg) translate(6px,-7px);-o-transform:rotate(-45deg) translate(6px,-7px);transform:rotate(-45deg) translate(6px,-7px);}
  .collapsed .middle-bar{opacity:1;}
  .collapsed .top-bar{-webkit-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
  .collapsed .bottom-bar{-webkit-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
  @media screen and (min-width:767px){
    .collapsed.navbar-toggle .icon-bar{width:36px;height:4px;}
    .collapsed.navbar-toggle .icon-bar+.icon-bar{margin-top:5px;}
  }
  #navUtility{font-family:"Open Sans",Arial,sans-serif;font-style:normal;font-weight:normal;text-align:center;text-transform:uppercase;-webkit-font-smoothing:subpixel-antialiased;margin-bottom:0;}
  #navUtility.navbar{border:none;}
  #navUtility.navbar-inverse{height:80px;padding-top:10px;}
  @media screen and (max-width:768px){
    #navUtility.navbar-inverse{height:58px;min-height:58px;position:relative;}
  }
  #navUtility.navbar-inverse .navbar-toggle{display:block;width:60px;height:60px;}
  @media screen and (max-width:768px){
    #navUtility.navbar-inverse .navbar-toggle{width:58px;height:58px;position:absolute;top:0;right:0;}
  }
  #navUtility.navbar-inverse .navbar-toggle:first-child{display:none;}
  @media screen and (max-width:768px){
    #navUtility.navbar-inverse .navbar-toggle:first-child{display:block;background:#fff;color:#ba0c2f;left:0;right:auto;}
  }
  #navUtility.navbar-inverse .navbar-toggle .icon-bar{margin-left:auto;margin-right:auto;}
  #navUtility.navbar-inverse .navbar-brand-inverse{float:left;display:block;}
  #navUtility.navbar-inverse .navbar-brand-inverse>img{width:auto;height:56px!important;display:block;}
  @media screen and (max-width:768px){
    #navUtility.navbar-inverse .navbar-brand-inverse{width:224px;height:48px;float:none;margin:0 auto;}
    #navUtility.navbar-inverse .navbar-brand-inverse>img{height:42px!important;}
  }
  #navUtility.navbar-inverse a.navbar-brand-inverse:focus{border-left:solid 1px #fff;border-right:solid 1px #fff;outline:0;}
  #navUtility.navbar-inverse .navbar-form{float:right;padding:10px 0;margin-right:10px;width:300px;}
  @media screen and (max-width:768px){
    #navUtility.navbar-inverse .navbar-form{display:none;}
  }
  #navUtility.navbar-inverse .navbar-form .input-group{width:300px;}
  #navUtility.navbar-inverse .navbar-form .form-control{height:40px;background-color:#9d0f27;border-color:#9d0f27;color:#fff;font-size:14px;-webkit-box-shadow:none;box-shadow:none;}
  #navUtility.navbar-inverse .navbar-form .input-group-btn{width:40px;background-color:#9d0f27;position:relative;}
  #navUtility.navbar-inverse .navbar-form .input-group-btn:after,#navUtility.navbar-inverse .navbar-form .input-group-btn:before{content:"";height:7px;width:1px;background:#9d0f27;left:0;top:0;display:block;position:absolute;z-index:100;}
  #navUtility.navbar-inverse .navbar-form .input-group-btn:after{top:auto;bottom:0;}
  #navUtility.navbar-inverse .navbar-form .btn.btn-default{width:39px;height:40px;font-size:20px;z-index:1;}
  #navUtility.navbar-inverse .navbar-form .btn.btn-default:hover,#navUtility.navbar-inverse .navbar-form .btn.btn-default:focus{color:#9d0f27;background:#fff;border-color:#fff;position:relative;z-index:1000;}
  #navUtility.navbar-inverse .navbar-search .container{padding:0;width:100%;background:#212527;}
  #navUtility.navbar-inverse .navbar-search .container .navbar-form{padding:7px;background:#212527;width:100%;float:none;margin:0;}
  #navUtility.navbar-inverse .navbar-search .container .navbar-form>.input-group{width:100%;float:none;}
  #navUtility.navbar-inverse .navbar-search .container .navbar-form>.input-group .form-control{font-family:"Open Sans",Arial,sans-serif;background:#212527;color:#fff;border:none;font-size:22px;font-weight:normal;height:26px;line-height:26px;padding-top:0;padding-bottom:0;}
  #navUtility.navbar-inverse .navbar-search .container .navbar-form>.input-group .form-control:focus{box-shadow:none;}
  #navUtility.navbar-inverse .navbar-search .container .navbar-form>.input-group .btn.btn-default{height:26px;border:none;border-left:1px solid #fff;background:none;}
  #navUtility.navbar-inverse .navbar-search .container .navbar-form>.input-group .btn.btn-default>.fa-search{color:#fff;position:relative;top:-7px;}
  @media screen and (max-width:767px){
    #navUtility.navbar-inverse .navbar-search .container .navbar-form>.input-group .input-group-btn{background:none;}
    #navUtility.navbar-inverse .navbar-search .container .navbar-form>.input-group .btn.btn-default{border:none;}
  }
  #navUtility .navbar-subpage{width:400px;background:#54585a;border:none;}
  @media screen and (max-width:767px){
    #navUtility .navbar-subpage{width:280px;}
  }
  #navUtility .navbar-subpage div{padding:30px 40px;}
  @media screen and (max-width:767px){
    #navUtility .navbar-subpage div{padding-left:15px;padding-right:15px;}
  }
  #navUtility .navbar-subpage div:first-child{padding-top:45px;}
  #navUtility .navbar-subpage div:last-child{padding-bottom:45px;}
  #navUtility .navbar-subpage div h5{font-family:"Open Sans",Arial,sans-serif;color:#fff;text-align:right;font-weight:bold;margin-bottom:25px;}
  #navUtility .navbar-subpage div .navbar-nav{display:block!important;}
  #navUtility .navbar-subpage div .navbar-nav>li{float:none;text-align:right;}
  #navUtility .navbar-subpage div .navbar-nav>li+li{margin-top:10px;}
  @media screen and (max-width:767px){
    #navUtility .navbar-subpage div .navbar-nav>li+li{margin-top:5px;}
  }
  #navUtility .navbar-subpage div .navbar-nav>li>a{color:#fff;padding-left:0;padding-right:0;font-size:26px;font-family:"Open Sans",Arial,sans-serif;text-transform:none;font-weight:600;font-style:normal;}
  @media screen and (max-width:767px){
    #navUtility .navbar-subpage div .navbar-nav>li>a{font-size:18px;}
  }
  #navUtility .navbar-subpage div .navbar-nav>li>a:hover{color:#a1a5a7;background:none;}
  #navUtility .navbar-subpage div .navbar-nav>li>a:focus{outline:0;text-decoration:underline;background:none;}
  #navUtility .navbar-subpage div .navbar-nav.small>li+li{margin-top:0;}
  #navUtility .navbar-subpage div .navbar-nav.small>li>a{font-family:"Open Sans",Arial,sans-serif;padding:7px 0;font-size:14px;text-transform:uppercase;font-weight:400;font-style:normal;}
  #navUtility .navbar-subpage div .navbar-nav.small>li.divider{border-top:1px dotted #fff;margin:20px 0;}
  #navUtility .navbar-subpage div .navbar-nav.social{background:none;text-align:right;margin-top:30px;}
  #navUtility .navbar-subpage div .navbar-nav.social.rowsub{margin-top:0;}
  @media screen and (max-width:767px){
    #navUtility .navbar-subpage div .navbar-nav.social{padding-top:20px;padding-bottom:0;margin-top:0;}
    #navUtility .navbar-subpage div .navbar-nav.social.rowsub{padding:0;}
  }
  #navUtility .navbar-subpage div .navbar-nav.social>li{display:inline-block;margin-left:20px;padding-top:10px;padding-bottom:10px;}
  #navUtility .navbar-subpage div .navbar-nav.social>li+li{margin-top:0;}
  #navUtility .navbar-subpage div .navbar-nav.social>li>a{font-size:30px;color:#fff;font-family:"Font Awesome 5 Free";font-weight:400;font-style:normal;padding:0;}
  #navUtility .navbar-subpage div .navbar-nav.social>li>a:hover{color:#a1a5a7;background:none;}
  #navUtility .navbar-subpage div .navbar-nav.social>li>a:focus{border:solid 1px #fff;outline:0;text-decoration:none;}
  #navUtility .navbar-form{font-family:"Open Sans",Arial,sans-serif;border:none;}
  #navUtility .navbar-toggle{color:#fff;width:48px;height:48px;padding:10px 12px;margin:0;background-color:#ba0c2f;border-radius:0;border:none;}
  #navUtility .navbar-toggle:hover{background-color:#666;}
  #navUtility .navbar-toggle:focus{background-color:#666;}
  #navUtility #btn-utility{float:left;}
  #navUtility .container{text-align:center;}
  #navUtility .navbar-form{margin:0;}
  #navUtility .navbar-toggle .fa-search{font-size:1.4em;}
  #navUtility .btn-default{color:#fff;background-color:#9d0f27;border:1px solid #9d0f27;border-left:1px solid #bb5768;}
  @media (max-width:767px){
    #navUtility .navbar-nav>li>a{font-size:20px;color:#32414d;}
    #navUtility .navbar-nav>li>a:hover{color:#ba0c2f;}
  }
  @media (min-width:768px){
    #navUtility{text-align:center;border-radius:0;}
    #navUtility .navbar-nav{padding:0;margin:0 auto;line-height:0;}
    #navUtility .navbar-form{width:140px;padding:0 0 0 5px;}
    #navUtility .navbar-nav{display:inline-block;float:none;}
    #navUtility .input-group{float:left;}
    #navUtility .input-group-sm .form-control{height:22px;padding:2px 6px;font-size:12px;line-height:1.5;border-radius:0;border-right:none;}
    #navUtility .input-group-sm>.input-group-btn>.btn{height:22px;padding:1px 6px;}
  }
  .navbar-inverse{background-color:#ba0c2f;border-color:#8a0923;}
  .navbar-inverse .navbar-nav>li>a{color:#fff;}
  .navbar-inverse .navbar-nav>li>a:hover,.navbar-inverse .navbar-nav>li>a:focus{color:#fff;background-color:#666;}
  .navbar-inverse .navbar-toggle{border-color:#333;}
  .navbar-inverse .navbar-toggle:hover,.navbar-inverse .navbar-toggle:focus{background-color:#666;}
  .navbar-inverse .navbar-toggle .icon-bar{background-color:#fff;}
  .navbar-inverse .navbar-form{border-color:#980a27;}
  .navbar-offcanvas{font-family:"Open Sans",Arial,sans-serif;width:300px;height:100%;border-width:1px;border-style:solid;border-radius:0;}
  .navmenu-fixed-right,.navbar-offcanvas{position:fixed;z-index:1030;top:0;border-radius:0;}
  .navmenu-fixed-right,.navbar-offcanvas{left:auto;right:0;border-width:0 0 0 1px;overflow-y:auto;}
  .navbar-offcanvas .navbar-nav{margin:0;}
  @media (min-width:768px){
    .navbar-offcanvas{width:auto;border-top:0;box-shadow:none;}
  }
  .offcanvas{display:none;}
  #utility-search.offcanvas .navbar-form{display:block;}
  #utility-search.offcanvas .navbar-form .btn.btn-default{border-left:1px solid #fff!important;}
  #utility-search.offcanvas .navbar-form .input-group-btn:before,#utility-search.offcanvas .navbar-form .input-group-btn:after{display:none!important;}
  .social{padding-top:30px;padding-bottom:25px;}
  @media screen and (min-width:768px){
    .social{text-align:center;}
  }
  /*! CSS Used from: https://bsu.edu/Components/Design/ResponsiveV2/css/v-638016029940000000/bsu-bootstrap-templates.css */
  @media print{
    *,*:before,*:after{color:#000!important;box-shadow:none!important;text-shadow:none!important;}
    a,a:visited{text-decoration:none;border-bottom:1px solid #000;}
    a[href]:after{content:" (" attr(href) ")";font-size:6pt;text-transform:lowercase!important;border-bottom:5px solid #fff!important;}
    img{page-break-inside:avoid;}
    img{max-width:50%!important;}
    a[href^="/"]:after{content:" (https://www.bsu.edu" attr(href) ")"!important;font-size:6pt!important;text-transform:lowercase!important;border-bottom:5px solid #fff!important;}
    .navbar{display:none;}
  }
  #navUtility .navbar-subpage div.utilnavwrapper{display:none;padding:0;}
  #navUtility .navbar-toggle.collapsed{padding:0 12px 10px 12px;}
  @media screen and (max-width:992px){
    #navUtility .navbar-subpage div.utilnavwrapper{display:block;}
  }
  .collapsed span.icon-bar.bottom-bar:after{content:"Menu";font-size:14px;width:100%;position:absolute;bottom:-20px;left:0;right:auto;}
  @media screen and (max-width:991px){
    .collapsed span.icon-bar.bottom-bar:after{display:none;}
    #navUtility .navbar-toggle.collapsed{padding:10px 12px;}
  }
  .center-block{display:block;margin-left:auto;margin-right:auto;}
  @media (max-width:767px){
    .hidden-xs{display:none!important;}
  }



}